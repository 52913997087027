(function() {
    angular.module('EntrakV5').controller('locationController', locationController);

    function locationController($scope, $rootScope, $state, $stateParams, Service, KEY, APIKEY, QR_TOGGLE, QR_VISITOR, Api) {
        console.log('locationController');

        //$stateParams.floorId
        var caller = Api.createApiCaller();
        $rootScope.title = Service.translate("location.title");

        // $scope.mixOnOff = APIKEY.mixOnOff;
        // $scope.allOn = APIKEY.allOn;
        // $scope.allOff = APIKEY.allOff;
        $scope.minTempDuration = KEY.minTempDuration;
        $scope.maxTempDuration = KEY.maxTempDuration;
        $scope.minDeltaTemp = KEY.minDeltaTemp;
        $scope.maxDeltaTemp = KEY.maxDeltaTemp;
        $scope.minDefaultTemp = KEY.minDefaultTemp;
        $scope.maxDefaultTemp = KEY.maxDefaultTemp;
        $scope.maxCo2 = KEY.maxCo2ppm;

        $scope.logic = APIKEY.logic;
        $scope.saving = {};    // {nodeId}

        $scope.statusMapModel = {};
        $scope.floor = {};
        $scope.deviceMap = {};
        $scope.corridorDevices = [];
        $scope.counts = {};
        $scope.tenantId = null;

        $scope.bookingSystemProvider = [];
        $scope.isMsTenant = false;
        $scope.isHabitapTenant = false;
		    $scope.isLoadingWorkStationAndRooms = false
        $scope.bookingSystems = {
          habitap: {},// nodeId: { nodeId, facility, externalId(added) }
          outlook: {}//TODOricky 
        };


        $scope.users = null;
        $scope.allZoneAdmins = null;

        $scope.step3 = {
            name: Service.translate("location.editFloorChild"),
        }
        $scope.steps = [{
            name: '-',
            navigate: function(){
                $state.go("selectFloor");
            }
        }, {
            name: '-',
            navigate: function(){
                $scope.cancelEditMode();
            }
        }];
        $scope.minLabel = Service.translate("label.minutes").toLowerCase();

        $scope.applicationTypes = [];
        for (var key in $rootScope.APPLICATION_TYPE){
            if (key !== APIKEY.applicationTypeInv.GATEWAY)
                $scope.applicationTypes.push({ type: $rootScope.APPLICATION_TYPE[key], name: Service.translate("applicationType."+key)});
        }
        $scope.applicationTypes.sort(Service.getSorter("name"));

    /* node navigation */
        function processWorkstation(node){
            Service.updateWsName(node);
            node.forSort = node.name;
        }
        function processRoom(node){
            node.forSort = '~' + node.name;
        }
		
		function processStationAndRooms(floor){
			 floor.workstations.forEach(processWorkstation);
			 floor.rooms.forEach(processRoom);
			 floor.nodes = floor.workstations.concat(floor.rooms);
			 //set offline count for disconnect popup
			 floor.count = { offline: 0 };
			 $scope.floor.devices.forEach(function(d){
			     if (!d.online && d.location)
			         floor.count.offline++;
			 });
			
			 $scope.floor = {...$scope.floor,...floor};
			 $scope.updateNodeListDisplay();
			 findCorridor();
			 //update status map
			 $scope.updateDisplayModeMaker();
					
			 //check data consistent
			 var missing = {};
			 floor.nodes.forEach(function(n){
				 n.deviceIds.forEach(function(deviceId){
					 if (!$scope.deviceMap[deviceId])
						 missing[deviceId] = deviceId;
				 });
			 });
			 missing = Object.keys(missing);
			 if (missing.length){
				 console.error("devices assigned to node but not found in this floor:", missing);
				 $rootScope.showError(Service.translate("error.missingDeviceInFloor"));
			 }
		}
        function processFloor(floor){
            $scope.steps[1].name = floor.name;
			      floor.gateways.reduce(function(arr, g){
    				return arr.concat(floor.devices.filter(function(d){
    					if (d.location == null)    //if null, device is not in this floor
    						return false;
    						if(g.id==d.gatewayId){
    							d.gSerial = g.serialId;		
    						}
    					return true;
    				}));
			}, []);
            $scope.deviceMap = Service.arrayToMap(floor.devices);
			// 合并之前的scope.floor
            $scope.floor = {...$scope.floor,...floor,isLoading:false};
            //update status map
            $scope.updateDisplayModeMaker();
        }

        function findCorridor(){
            $scope.corridorDevices = [];
            $scope.floor.devices.forEach(function(d){
                for (var i=0; i<$scope.floor.nodes.length; i++){
                    if ($scope.floor.nodes[i].deviceIds.indexOf(d.id) != -1)
                        return;
                }
                $scope.corridorDevices.push(d.id);
            });
			$scope.editMapModel.floorLocations=$scope.floor.nodes
        }

        //first load
        function processHabitapFacilityMapping(r) {
          r.externalId = r.facility;
        }
        $rootScope.loadingPage = 0;
        $rootScope.getTenantId().then(function(tenantId){
            $scope.tenantId = tenantId;
            $scope.steps[0].name = $rootScope.currentUser.tenant.name;
            $scope.isMsTenant = $rootScope.currentUser.tenant.serviceCredentials.length > 0;
            if ($scope.isMsTenant)
                $scope.bookingSystemProvider.push({
                    id: "outlook",
                    text: Service.translate("location.outlook"),
                    inputLabel: Service.translate("location.outlookExternalId")
                });
            $rootScope.loadingPage++;
            caller.call(Api.getTenantHabitapMapping(tenantId)).then(function(res){
                if (res && res.clientId) {
                    $rootScope.loadingPage++;
                    caller.call(Api.getHabitapFacilityMappings($stateParams.floorId)).then(function(res){
                        res.forEach(processHabitapFacilityMapping);
                        $scope.bookingSystems.habitap = Service.arrayToMap(res, "nodeId");
                        $rootScope.loadingPage--;
                    }, function(err){
                        if (err === KEY.ignore)
                            return;
                        $rootScope.generalErrorHandler();
                    });                    
                    $scope.isHabitapTenant = true;
                    $scope.bookingSystemProvider.push({
                        id: "habitap",
                        text: Service.translate("location.habitap"),
                        inputLabel: Service.translate("location.habitapExternalId")
                    });
                }
                $scope.bookingSystemDropdown.setDataSource(new kendo.data.DataSource({
                    data: $scope.bookingSystemProvider
                }));
                $rootScope.loadingPage--;
            }, function(err){
                if (err === KEY.ignore)
                    return;
                $rootScope.generalErrorHandler();
            });

            $rootScope.loadingPage++;
            caller.call(Api.getLocationPage($stateParams.floorId)).then(function(res){
                processFloor(res);
                $rootScope.loadingPage--;
            }, function(err){
                if (err === KEY.ignore)
                    return;
                $rootScope.generalErrorHandler();
            });

            $rootScope.loadingPage++;
            caller.call([Api.getProfiles(tenantId, 1), Api.getAssignedZoneAdmins($stateParams.floorId)]).then(function(res){
                $scope.users = res[0].sort(Service.getSorter("email"));
                $scope.allZoneAdmins = $scope.users.filter(function(u){
                    return u.aclRole === APIKEY.role.zoneAdmin;
                });
                $scope.editFloorData.zoneAdmins = [];
                res[1].forEach(admin => $scope.editFloorData.zoneAdmins.push(Service.getArrItem($scope.users, admin.userId)));
                $scope.zoneAdminCombo.setDataSource(new kendo.data.DataSource({
                    data: $scope.allZoneAdmins
                }));
                $rootScope.loadingPage--;
            }, function(err){
                if (err === KEY.ignore)
                    return;
                $rootScope.generalErrorHandler();
            });
        });

        $scope.stepBack = function($i, $node){
            if ($i == 0){
                window.history.back();
            } else {
                $node.navigate();
            }
        }
        $scope.stepNavigate = function($node){
            $node.navigate();
        }

        $scope.getZoneAdminsLbl = function(){
            return $scope.editFloorData.zoneAdmins ? $scope.editFloorData.zoneAdmins.map(a => a.email).join(", ") : '';
        }
    /* node navigation */

    /* display view */
        $scope.showingLeaf1 = true;
        $scope.showingLeaf2 = false;
        $scope.noBorderIndex = null;    //for display only

        //for ui display only
        $scope.updateNodeListDisplay = function(){
            var tmp = $scope.floor.nodes.length % 3;
            tmp = tmp == 0 ? 3 : tmp;
            $scope.noBorderIndex = $scope.floor.nodes.length - tmp;
        }
        $scope.nodeSort = function(a, b){
            return a.value > b.value ? 1 : -1;
        }
        $scope.filterNode = function(){
            var text = ($scope.editMapModel.searchText ? $scope.editMapModel.searchText.trim().toLowerCase() : null);
            return function(item){
                if (text == null)
                    return true;

                if (item.name.toLowerCase().indexOf(text) > -1)
                    return true;
                if (item.defaultName && item.defaultName.toLowerCase().indexOf(text) > -1)
                    return true;
                for (var i=0; i<item.deviceIds.length; i++){
                    if ($scope.deviceMap[item.deviceIds[i]].serialId.toLowerCase().indexOf(text) > -1)
                        return true;
                }
                if (item.owners){
                    for (var i=0; i<item.owners.length; i++){
                        if (item.owners[i].email.indexOf(text) > -1)
                            return true;
                    }
                }
                return false;
            }
        }
        $scope.filterCorridor = function(){
            var text = ($scope.editMapModel.searchText ? $scope.editMapModel.searchText.trim().toUpperCase() : null);
            if (text == null)
                return true;
            for (var i=0; i<$scope.corridorDevices.length; i++){
                if ($scope.deviceMap[$scope.corridorDevices[i]].serialId.toUpperCase().indexOf(text) > -1)
                    return true;
            }
            return Service.translate("location.corridorDevices").toUpperCase().indexOf(text) > -1;
        }

        $scope.isWs = function(node){
            return Service.isWorkstation(node);
        }
        $scope.getNodeClass = function(isWs){
            return isWs ? APIKEY.nodeTypeInv[APIKEY.nodeType.workstation] : APIKEY.nodeTypeInv[APIKEY.nodeType.room];
        }

        $scope.toggleFloorDevice = function(isTurnOn){
            $scope.floor.isLoading = true;
            $rootScope.loadingPage++;
            caller.call(Api.turnAllOnZone($scope.floor.id, isTurnOn)).then(function(res){
				$rootScope.loadingPage--;
                processFloor(res);
              
            }, function(err){
                if (err === KEY.ignore)
                    return;
                $rootScope.generalErrorHandler();
            });
        }

        $scope.updateDisplayModeMaker = function(){
            for (var k in APIKEY.applicationTypeInv){
                $scope.counts[k] = { total: 0, online: 0 };
            }
            $scope.statusMapModel.markers = $scope.floor.devices;
            $scope.statusMapModel.markers.forEach(function(d){
                d.cssClass = d.status ? "on" : null;
                $scope.counts[d.applicationType].total++;
                if (d.online)
                    $scope.counts[d.applicationType].online++;
            });
        }
		
        $scope.showEditMode = function(){
            $rootScope.isEditing = true;
			if($scope.isLoadingWorkStationAndRooms){
				afterPageDataLoaded()
				$scope.toggleView('device')
				return;
			}
			$rootScope.loadingPage++;
			caller.call(Api.getLocationWorkStationAndRooms($stateParams.floorId)).then(function(floor){
				processStationAndRooms(floor)
				$scope.isLoadingWorkStationAndRooms = true
				$rootScope.loadingPage--;
				afterPageDataLoaded()
				$scope.toggleView('device')
			}, function(err){
			    if (err === KEY.ignore)
			        return;
			    $rootScope.generalErrorHandler();
			});
           		
        }
		
		function afterPageDataLoaded(){
			$scope.editMapModel.markers = $scope.floor.devices;
			$scope.editMapModel.searchText = '';
			$scope.clearNodeSelection();
			$scope.steps.push($scope.step3);
			let timer = setTimeout(function(){
			    $scope.deviceParentsPopup.setOptions({anchor: $("#location .tooltipAnchor div")});
				window.setTimeout(timer)
			});	
		}

        $scope.editFloorData = {};
        $scope.editFloorWinOpt = {
            title: Service.translate("location.titleEditFloor"),
            width: "800px",
            modal: true,
            draggable: false,
            visible: false,
            resizable: false,
            actions: ["Close"]
        }
        $scope.showEditFloorWin = function(){
            $scope.editFloorData.saving = false;
            $scope.editFloorData.name = $scope.floor.name;
            $scope.editFloorData.offDelay = $scope.floor.closeDelay;
            $scope.editFloorData.tempDuration = Math.ceil($scope.floor.defaultConditionDuration);
            if ($scope.editFloorData.tempDuration > $scope.maxTempDuration){
                $scope.editFloorData.tempDuration = $scope.maxTempDuration;
            } else if ($scope.editFloorData.tempDuration < $scope.minTempDuration){
                $scope.editFloorData.tempDuration = $scope.minTempDuration;
            }
            $scope.editFloorData.coolerDelta = Math.floor($scope.floor.defaultCoolerDelta/0.5) * 0.5;
            if ($scope.editFloorData.coolerDelta > $scope.maxDeltaTemp){
                $scope.editFloorData.coolerDelta = $scope.maxDeltaTemp;
            } else if ($scope.editFloorData.coolerDelta < $scope.minDeltaTemp){
                $scope.editFloorData.coolerDelta = $scope.minDeltaTemp;
            }
            $scope.editFloorData.warmerDelta = Math.floor($scope.floor.defaultWarmerDelta/0.5) * 0.5;
            if ($scope.editFloorData.warmerDelta > $scope.maxDeltaTemp){
                $scope.editFloorData.warmerDelta = $scope.maxDeltaTemp;
            } else if ($scope.editFloorData.warmerDelta < $scope.minDeltaTemp){
                $scope.editFloorData.warmerDelta = $scope.minDeltaTemp;
            }
            $scope.editFloorData.heatMode = $scope.floor.heatMode;
            $scope.editFloorData.alwaysOn = $scope.floor.alwaysOn;

            setTimeout(function(){
                $scope.editFloorWin.center().open();
            });
        }

        $scope.saveFloorInfo = function(){
            $scope.editFloorData.saving = true;
            $rootScope.loadingPage++;
            $scope.editFloorData.tempDuration = Math.max(1, $scope.editFloorData.tempDuration);
            $scope.editFloorData.coolerDelta = Math.max(0.5, $scope.editFloorData.coolerDelta);
            $scope.editFloorData.warmerDelta = Math.max(0.5, $scope.editFloorData.warmerDelta);
            $scope.editFloorData.offDelay = Math.max(0, $scope.editFloorData.offDelay);
            caller.call(Api.updateFloorInfo($scope.floor.id, $scope.editFloorData.tempDuration, $scope.editFloorData.coolerDelta, $scope.editFloorData.warmerDelta, $scope.editFloorData.heatMode, $scope.editFloorData.alwaysOn, $scope.editFloorData.offDelay)).then(function(res){
				        processFloor(res);
                $scope.editFloorData.saving = false;
                $rootScope.loadingPage--;
                $scope.editFloorWin.close();
            }, function(err){
                if (err === KEY.ignore)
                    return;
                $scope.editFloorData.saving = false;
                $rootScope.generalErrorHandler();
            });
        }
        $scope.selectZoneAdminWinOpt = {
            title: Service.translate("location.zoneAdmin"),
            width: "550px",
            modal: true,
            draggable: false,
            visible: false,
            resizable: false,
            actions: ["Close"]
        }
        $scope.zoneAdminComboOpt = {
            filter: "contains",
            clearButton: false,
            filterFields: ["email"],
            highlightFirst: false,
            dataTextField: "email",
            dataValueField: "email",
            dataSource: [],
            columns: [
                { field: "email", template: "#:email#" },
            ],
            suggest: true
        }

        $scope.getNewZoneAdmin = function(){
            var email = ($scope.editFloorData.newZoneAdmin || '').trim().toLowerCase();
            if (email)
                return Service.getArrItem($scope.allZoneAdmins, email, "email");
        }

        $scope.showZoneAdmin = function(){
            $scope.editFloorData.showZoneAdminInput = false;
            setTimeout(function(){
                $scope.selectZoneAdminWin.center().open();
            });
        }
        $scope.showAddZoneAdminInput = function(){
            $scope.editFloorData.showZoneAdminInput = true;
            $scope.editFloorData.newZoneAdmin = '';
            $("#selectZoneAdminWin .cardScroller").animate({
                scrollTop: document.querySelector("#selectZoneAdminWin .cardScroller").scrollHeight
            }, "fast");
        }
        $scope.addZoneAdmin = function(){
            var tmp = $scope.editFloorData;
            var admin = $scope.getNewZoneAdmin();
            if (Service.getArrItem(tmp.zoneAdmins, admin.id)){
                tmp.showZoneAdminInput = false;    
            } else {
                $scope.saving.zoneAdmin = true;
                caller.call(Api.assignZoneAdmin(admin.id, $stateParams.floorId)).then(function(res){
                    tmp.zoneAdmins.push(admin);
                    tmp.showZoneAdminInput = false;
                    $scope.saving.zoneAdmin = false;
                }, function(err){
                    if (err === KEY.ignore)
                        return;
                    $scope.saving.zoneAdmin = false;
                    $rootScope.showError(Service.translate("error.generalAssignZoneAdminFail"));
                })
            }
        }
        $scope.cancelAddZoneAdmin = function(){
            $scope.editFloorData.showZoneAdminInput = false;
            $scope.editFloorData.newZoneAdmin = '';
        }
        $scope.removeZoneAdmin = function(admin){
            var tmp = $scope.editFloorData;
            $rootScope.deletePopup.show("location.deleteZoneAdminTitle", "location.deleteZoneAdminDesc", admin.email, function(){
                caller.call(Api.unassignZoneAdmin(admin.id, $stateParams.floorId)).then(function(res){
                    Service.deleteArrItem(tmp.zoneAdmins, admin);
                    $rootScope.deletePopup.close();
                }, function(err){
                    if (err === KEY.ignore)
                        return;
                    $rootScope.showError(Service.translate("error.generalDelZoneAdminFail"));
                });
            });
        }
    /* display view */

    /* edit floor child view */
        $rootScope.setEditing(false);
        $scope.isDimMarker = false;
        $scope.hoveredMarker = null;
        $scope.hoveredNodeElm = null;
        $scope.editMapModel = {polygonActiveNode:null,etDrawWayModel:'polygon'};

        $scope.clearNodeSelection = function(){
            $scope.editMapModel.markers.forEach(function(d){
                d.cssClass = null;
            });
            if ($scope.hoveredNodeElm){
                $scope.hoveredNodeElm.removeClass("hover");
                $scope.hoveredNodeElm = null;
            }
            $scope.hoveredMarker = null;
            $scope.isDimMarker = false;
			$scope.isPosEdit = false;
			if($scope.editMapModel.isPosEdit){//取消编辑模式
				// 是否处于位置编辑模式
				$scope.editMapModel.isPosEdit=false
				 $scope.editMapModel.markers=$scope.floor.devices
			}
			
            $scope.editMapModel.editingNodeData = {};    //set id to true for creating new zone
        }

        $scope.cancelEditMode = function(){
            $scope.updateDisplayModeMaker();
            $scope.updateNodeListDisplay();
            $rootScope.isEditing = false;
        }

        //when node selected, click on marker to add/remove device
        //when no selected node, show popup
        $scope.clickMarker = function($i){
            if ($scope.editMapModel.editingNodeData.id === 'corridor'){
                $scope.clearNodeSelection();
                return;
            }

            if ($scope.editMapModel.editingNodeData.id){
                var marker = $scope.editMapModel.markers[$i];
                if (marker.cssClass == null){
                    marker.cssClass = "on";
                    $scope.editMapModel.editingNodeData.deviceIds.push(marker.id);
                } else if (marker.cssClass === "on"){
                    marker.cssClass = null;
                    $scope.editMapModel.editingNodeData.deviceIds.splice($scope.editMapModel.editingNodeData.deviceIds.indexOf(marker.id), 1);
                }
            } else {
                $scope.showDeviceParentsPopup($i);
            }
        }

        //change marker style when hover node
        $scope.hoverNode = function(isHover, node, $event){
            if ($scope.editMapModel?.editingNodeData?.id)
                return;
		    $scope.editMapModel.polygonActiveNode = node
			$scope.editMapModel.isHover = isHover,
            $scope.isDimMarker = isHover;
            var target = angular.element($event.target);
            if (!target.hasClass("nodeType"))
                target = target.parent();

            if (isHover){
                target.addClass("hover");
                $scope.hoveredNodeElm = target;
            } else {
                target.removeClass("hover");
            }

            if (node){
                node.deviceIds.forEach(function(markerId){
                    $scope.deviceMap[markerId].cssClass = isHover ? "on noDim" : null;
                });
            } else {
                $scope.corridorDevices.forEach(function(deviceId){
                    $scope.deviceMap[deviceId].cssClass = isHover ? "on noDim" : null;
                });
            }
        }

        $scope.showCorridorDevices = function(){
            $scope.editMapModel.editingNodeData = {
                deviceIds: $scope.corridorDevices,
                id: "corridor",
                adminOnly: false,
            }
        }

        //show edit box when select node in popup or item list
        $scope.editNode = function(node){
            if ($scope.saving[node.id])
                return;
			// 取消位置编辑模式
			// $scope.editMapModel.isPosEdit=false
			$scope.editMapModel.markers = $scope.floor.devices
			$scope.editMapModel.boundaryView=false
            var isWs = Service.isWorkstation(node);
            $scope.editMapModel.editingNodeData = {
                deviceIds: node.deviceIds.slice(),
                name: node.name,
                id: node.id,
                isWs: isWs,
                adminOnly: node.adminOnly,
                delegations: node.delegations,
                sharable: node.sharable,
            }
            if (isWs){
                $scope.editMapModel.editingNodeData.defaultName = node.defaultName;
                $scope.editMapModel.editingNodeData.isHotDesk = node.isHotDesk;
                $scope.editMapModel.editingNodeData.owners = node.owners;
            }

            $scope.isDimMarker = false;

            //assume all markers except node.devices have cssClass = null when calling this function
            if (isWs){
                $scope.floor.nodes.forEach(function(n){
                    if (!Service.isWorkstation(n)){
                        n.deviceIds.forEach(function(id){
                            $scope.deviceMap[id].cssClass = "dim";
                        });
                    }
                });
            } else {
                $scope.floor.nodes.forEach(function(n){
                    n.deviceIds.forEach(function(id){
                        $scope.deviceMap[id].cssClass = "dim";
                    });
                });
            }
            node.deviceIds.forEach(function(id){
                $scope.deviceMap[id].cssClass = "on";
            });

            $scope.deviceParentsPopup.close();
        }

        //show edit box when clicking create in popup
        $scope.createNode = function(nodeType){
            var node = {
                id: true,
                name: "",
                deviceIds: [$scope.hoveredMarker.id],
                adminOnly: false,
                isHotDesk: false,
                sharable: false,
            }
            if (nodeType === APIKEY.nodeType.workstation)
                node.owners = [];
            $scope.editNode(node);
        }

        $scope.qrWinOpt = {
            title: Service.translate("location.qrCode"),
            width: "570px",
            modal: true,
            draggable: false,
            visible: false,
            resizable: false,
            actions: ["Close"]
        }
        $scope.qrCodeOpt = {
            renderAs: "canvas",
            size: 190,
            padding: 0
        }

        $scope.showQrWin = function(){
            $scope.qrCode.value(QR_TOGGLE + $scope.editMapModel.editingNodeData.id);
            //use image so that chrome and safari can also right click copy img
            $scope.qrCode.exportImage().done(function(data) {
                var img = document.getElementById("qrCodeUser");
                img.src = data;
                $scope.qrCode.value(QR_VISITOR + $scope.tenantId + "/" + $scope.editMapModel.editingNodeData.id);
                $scope.qrCode.exportImage().done(function(data) {
                    var img = document.getElementById("qrCodeVisitor");
                    img.src = data;
                    $scope.qrWin.center().open();
                });
            });
        }

        $scope.showDelegatePopup = function(){
            var data = $scope.editMapModel.editingNodeData;
            var id = data.id;
            $rootScope.showDelegateWsWin(id, data.name, $scope.users, data.delegations.map(function(d){
                return d.userId;
            }), function(delegateList){
                Service.getArrItem($scope.floor.nodes, id).delegations = delegateList;
                data.delegations = delegateList;
            });
        }

        $scope.getNodeOwners = function(){
            return $scope.editMapModel.editingNodeData.owners.map(o => o.email).join(", ");
        }

        $scope.deleteNode = function(){
            if ($scope.editMapModel.editingNodeData.id === true){    //delete new node
                $scope.clearNodeSelection();
            } else if ($scope.editMapModel.editingNodeData.id) {    //delete old node
                $scope.editMapModel.editingNodeData.editErr = null;
                var isWs = $scope.editMapModel.editingNodeData.isWs;
                var name = $scope.editMapModel.editingNodeData.name;
                $rootScope.deletePopup.show((isWs ? "location.titleDeleteWorkstation" : "location.titleDeleteRoom"), "location.deleteFloorChildDesc", name, function(){
                    var id = $scope.editMapModel.editingNodeData.id;
                    $scope.saving[id] = true;
                    caller.call(isWs ? Api.deleteWorkstation(id) : Api.deleteRoom(id)).then(function(){
                        Service.deleteArrItem($scope.floor.nodes, id);
                        if ($scope.editMapModel.editingNodeData.id === id){
                            $rootScope.deletePopup.close();
                            $scope.clearNodeSelection();
                        }
                        findCorridor();
                        $scope.saving[id] = false;
                    }, function(err){
                        if (err === KEY.ignore)
                            return;
                        $rootScope.deletePopup.close();
                        showSaveNodeErr(id, name, (isWs ? "error.generalDelWsFail" : "error.generalDelRmFail"));
                        $scope.saving[id] = false;
                    });
                });
            } else {
                console.error("nothing to delete", $scope.editMapModel.editingNodeData.id);
            }
        }

        $scope.saveNode = function(){
            $scope.editMapModel.editingNodeData.editErr = null;
            var id = $scope.editMapModel.editingNodeData.id;
            var locIds = $scope.editMapModel.editingNodeData.deviceIds.map(function(id){
                return $scope.deviceMap[id].location.id;
            });
            if ($scope.editMapModel.editingNodeData.isWs){
                var defaultName = ($scope.editMapModel.editingNodeData.defaultName || '').trim();
                $scope.saving[id] = true;
                if (id === true){
                    var action = Api.createWorkstation($scope.floor.id, defaultName, locIds, $scope.editMapModel.editingNodeData.adminOnly, $scope.editMapModel.editingNodeData.isHotDesk, $scope.editMapModel.editingNodeData.sharable);
                } else {
                    var action = Api.updateWorkstation(id, defaultName, locIds, $scope.editMapModel.editingNodeData.adminOnly, $scope.editMapModel.editingNodeData.isHotDesk, $scope.editMapModel.editingNodeData.sharable);
                }
                caller.call(action).then(function(node){
                    processWorkstation(node);
                    Service.replaceArrItem($scope.floor.nodes, node, true);
                    Service.replaceArrItem($scope.floor.workstations, node, true);
                    if ($scope.editMapModel.editingNodeData.id === id)
                        $scope.clearNodeSelection();
                    findCorridor();
                    $scope.saving[id] = false;
                }, function(err){
                    if (err === KEY.ignore)
                        return;
                    if (err.graphQLErrors && err.graphQLErrors[0].message.lastIndexOf("workstation_owners_pkey") != -1){
                        showSaveNodeErr(id, defaultName, "error.ownerHasWorkstation");
                    } else {
                        showSaveNodeErr(id, defaultName, "error.generalSaveFail");
                    }
                    $scope.saving[id] = false;
                });
            } else {
                var name = $scope.editMapModel.editingNodeData.name.trim();
                $scope.saving[id] = true;
                if (id === true){
                    var action = Api.createRoom($scope.floor.id, name, locIds, $scope.editMapModel.editingNodeData.adminOnly);
                } else {
                    var action = Api.updateRoom(id, name, locIds, $scope.editMapModel.editingNodeData.adminOnly);
                }
                caller.call(action).then(function(node){
                    processRoom(node);
                    Service.replaceArrItem($scope.floor.nodes, node, true);
                    Service.replaceArrItem($scope.floor.rooms, node, true);
                    findCorridor();
                    if ($scope.editMapModel.editingNodeData.id === id)
                        $scope.clearNodeSelection();
                    $scope.saving[id] = false;
                }, function(err){
                    if (err === KEY.ignore)
                        return;
                    // TODOricky outlook
                    // if (err.graphQLErrors && err.graphQLErrors[0].message && err.graphQLErrors[0].message.indexOf("code = Code(404)") > -1){
                    //     showSaveNodeErr(id, name, "error.invalidBookingEmail");
                    // } else {
                    showSaveNodeErr(id, name, "error.generalSaveFail");
                    // }
                    $scope.saving[id] = false;
                });
            }
        }
        function showSaveNodeErr(nodeId, name, errCode){
            if ($scope.editMapModel.editingNodeData.id === nodeId){
                $scope.editMapModel.editingNodeData.editErr = Service.translate(errCode);
            } else {
                $rootScope.showError(Service.translate(errCode) + "\n" + name);
            }
        }

        $scope.selectOwnerWinOpt = {
            title: Service.translate("button.selectOwners"),
            width: "550px",
            modal: true,
            draggable: false,
            visible: false,
            resizable: false,
            actions: ["Close"]
        }
        $scope.ownerComboOpt = {
            filter: "contains",
            clearButton: false,
            filterFields: ["email"],
            highlightFirst: false,
            dataTextField: "email",
            dataValueField: "email",
            dataSource: [],
            columns: [
                { field: "email", template: "#:email#" },
            ],
            suggest: true,
            open: function(){
                this.setDataSource(new kendo.data.DataSource({
                    data: $scope.users
                }));
            }
        }

        $scope.getNewOwner = function(){
            var email = ($scope.editMapModel.editingNodeData.newOwner || '').trim().toLowerCase();
            if (email)
                return Service.getArrItem($scope.users, email, "email");
        }

        $scope.showEditOwners = function(){
            $scope.editMapModel.editingNodeData.showInput = false;
            setTimeout(function(){
                $scope.selectOwnerWin.center().open();
            });
        }
        $scope.showAddOwnerInput = function(){
            $scope.editMapModel.editingNodeData.showInput = true;
            $scope.editMapModel.editingNodeData.newOwner = '';
            $("#selectOwnerWin .cardScroller").animate({
                scrollTop: document.querySelector("#selectOwnerWin .cardScroller").scrollHeight
            }, "fast");
        }
        $scope.addOwner = function(){
            var tmp = $scope.editMapModel.editingNodeData;
            var tmpId = tmp.id;
            var newOwner = $scope.getNewOwner();
            if (Service.getArrItem(tmp.owners, newOwner.id)){
                tmp.showInput = false;    
            } else {
                $scope.saving.owner = true;
                caller.call(Api.assignWorkstationOwner(tmpId, newOwner.id)).then(function(res){
                    for (var i=0; i<$scope.floor.workstations.length; i++){
                        var ws = $scope.floor.workstations[i];
                        if (Service.deleteArrItem(ws.owners, newOwner.id)){
                            Service.refreshWsName(ws);
                            break;
                        }
                    }
                    var ws = Service.getArrItem($scope.floor.workstations, tmpId);
                    ws.owners.push(newOwner);
                    Service.refreshWsName(ws);
                    tmp.showInput = false;
                    $scope.saving.owner = false;
                }, function(err){
                    if (err === KEY.ignore)
                        return;
                    $scope.saving.owner = false;
                    $rootScope.showError(Service.translate("error.generalAssignOwnerFail"));
                });
            }
        }
        $scope.cancelAddOwner = function(){
            $scope.editMapModel.editingNodeData.showInput = false;
            $scope.editMapModel.editingNodeData.newOwner = '';
        }
        $scope.removeOwner = function(o){
            var tmp = $scope.editMapModel.editingNodeData;
            var tmpId = tmp.id;
            $rootScope.deletePopup.show("location.deleteOwnerTitle", "location.deleteOwnerDesc", o.email, function(){
                caller.call(Api.releaseWorkstationOwner(tmpId, o.id)).then(function(res){
                    var ws = Service.getArrItem($scope.floor.workstations, tmpId);
                    Service.deleteArrItem(ws.owners, o);
                    Service.refreshWsName(ws);
                    $rootScope.deletePopup.close();
                }, function(err){
                    if (err === KEY.ignore)
                        return;
                    $rootScope.showError(Service.translate("error.generalDelOwnerFail"));
                });
            });
        }

        $scope.editPolygonWinOpt = {
            title: Service.translate("button.editPolygon"),
            width: '70%',
            modal: true,
            draggable: false,
            visible: false,
            resizable: false,
            actions: ["Close"],
			onOk:function(){
			}
        }
        $scope.polygonData = {};
		// 视图切换：boundary 边界视图  device:设备视图
		$scope.toggleView=function(view){
			 $scope.isPosEdit=false
			 $scope.editMapModel.isPosEdit=false
			 if(view=='boundary'){
				 $scope.editMapModel.markers = []
				 $scope.editMapModel.boundaryView=true
			 }else{
				 $scope.editMapModel.markers = $scope.floor.devices
				 $scope.editMapModel.boundaryView=false
			 }
			 $scope.clearNodeSelection()
		}
		$scope.handleZoomPolygon= function(current){
			// 放大还是缩小 ：in(放大) out(缩小) reset（重置）
			$scope.editMapModel.zoomType=current
		}
    $scope.handleDrawWay= function(way,event){
        event.stopPropagation()
        $scope.editMapModel.etDrawWayModel=way
    }

    $scope.showEditPolygon = function(){
			
			// 编辑边界模式为true
		  $scope.isPosEdit=true
		  // 是否处于位置编辑模式
		  $scope.editMapModel.isPosEdit=$scope.isPosEdit
		  if($scope.isPosEdit){
			  // 深拷贝markers，临时存储
			  $scope.editMapModel.markers=$scope.floor.devices
		  }else{
			   $scope.editMapModel.markers=$scope.floor.devices
			   $scope.editMapModel.boundaryView=false
		  }
		  
          $scope.polygonData.saving = false;
          $scope.polygonData.id = $scope.editMapModel.editingNodeData.id;
          $scope.polygonData.polygonString = Service.getArrItem($scope.floor.nodes, $scope.polygonData.id).locationPolygon;
		  // $scope.editMapModel.polygonString=$scope.polygonData.polygonString
		  $scope.editMapModel.polygonString=$scope.svgToCanvas($scope.polygonData.polygonString)
          $scope.polygonData.isWs = $scope.editMapModel.editingNodeData.isWs;
          let time=setTimeout(function(){
            $scope.editPolygonWin.center().open();
			
			window.clearTimeout(time)
          });
        }
		// svg ==> canvas 
		$scope.svgToCanvas=function(loc){
			let arr=loc.split(' ')
			let result=''
			arr.forEach((item,index)=>{
				result+=item.split(',').join(' ')+','
			})	
			result=result.substring(0,result.lastIndexOf(','))
			return result;
		}
		// canvas ===> svg
		$scope.canvasToSvg=function(loc){
			let arr=loc.split(' ')
			let result=''
			arr.forEach((item,index)=>{
				result+=item.split(',').join(' ')+','
			})	
			result=result.substring(0,result.lastIndexOf(',')).trim()
			return result
		}
		$scope.deletePolygon=function(){
			$scope.editMapModel.polygonString=''
			$scope.editMapModel.isDelete=true
			$scope.savePolygon(true)
		}
        $scope.savePolygon = function(flag){
			
			// 获取最终的边界值
			const {polygonString} = $scope.editMapModel
			// 把canvas格式的值转换为svg格式
			$scope.polygonData.polygonString =$scope.canvasToSvg(polygonString)
			  $scope.polygonData.saving = true;
			  $scope.editMapModel.saving=true
			  var id = $scope.polygonData.id;
			  caller.call(Api[$scope.polygonData.isWs ? "updateWorkstationLocation" : "updateRoomLocation"](id, $scope.polygonData.polygonString)).then(function(res){
				Service.getArrItem($scope.floor.nodes, id).locationPolygon = res.locationPolygon;
				$scope.polygonData.saving = false;
				if(!flag){
					$scope.editPolygonWin.close();	
				}
				$scope.editMapModel.saving=false//保存成功
        $scope.editMapModel.etDrawWayModel='polygon'

				$scope.isPosEdit=false//

				$scope.polygonData.polygonString=''
			  }, function(err){
				if (err === KEY.ignore)
				  return;
				$scope.polygonData.saving = false;
				$rootScope.showError(Service.translate("error.generalUpdatePolygonFail"));
			  });
        }

        //booking system popup
        $scope.bookingPopupOpt = {
            origin: "top center",
            position: "top center"
        }
        $scope.bookingSystemDropdownOpt = {
            autoWidth: true,
            dataSource: [],
            dataTextField: "text",
            dataValueField: "id"
        }
        $scope.bookingData = {};

        $scope.getBookingSystemId = function(nodeId){//assume one room cannot have multiple externalId
          if ($scope.isHabitapTenant) {
            if ($scope.bookingSystems.habitap[nodeId])
              return "habitap";
          }
          if ($scope.isMsTenant) {
            if ($scope.bookingSystems.outlook[nodeId])
            return "outlook";
          }
          return null;
        }
        $scope.getBookingSystemName = function(bsId) {
          return bsId ? Service.translate("location." + bsId) : "-";
        }
        $scope.getExternalId = function(nodeId){//assume one room cannot have multiple externalId
          if ($scope.isHabitapTenant) {
            if ($scope.bookingSystems.habitap[nodeId])
              return $scope.bookingSystems.habitap[nodeId].externalId;
          }
          if ($scope.isMsTenant) {
            if ($scope.bookingSystems.outlook[nodeId])
            return $scope.bookingSystems.outlook[nodeId].externalId;
          }
          return null;
        }

        $scope.externalIdLabel = function(){
          var sys = $scope.bookingSystemProvider.find(p => p.id === $scope.bookingData.bookingSystemId);
          return sys ? sys.inputLabel : '';
        }

        $scope.showBookingPopup = function(rmId){
            $scope.bookingData.rmId = rmId;
            $scope.bookingData.bookingSystemId = $scope.getBookingSystemId(rmId);
            $scope.bookingData.externalId = $scope.bookingData.bookingSystemId ? $scope.bookingSystems[$scope.bookingData.bookingSystemId][rmId].externalId : null;
            $scope.bookingData.hasExternalId = !!$scope.bookingData.externalId;
            $scope.bookingPopup.setOptions({ anchor: $scope.bookingData.externalId ? $("#location #externalIdBtn") : $("#location #externalIdEmptyBtn")});
            setTimeout(function(){
              $scope.bookingPopup.open();
            }, 50);
        }
        $scope.deleteExternalId = function(){
          var name = $scope.editMapModel.editingNodeData.name;
          var id = $scope.bookingData.rmId;
          var bookingSystemId = $scope.getBookingSystemId(id);
          $rootScope.deletePopup.show("location.deleteExternalId", "location.deleteExternalIdDesc", name, function(){
            caller.call(Api.deleteHabitapFacilityMapping(id)).then(function(res){
                $scope.bookingSystems[bookingSystemId][id] = null;
                $scope.bookingPopup.close();
            }, function(err){
                if (err === KEY.ignore)
                    return;
                $rootScope.generalErrorHandler();
            });               
          });
        }
        $scope.saveExternalId = function(){
          var id = $scope.bookingData.rmId;
          var currentBookingSystemId = $scope.bookingData.hasExternalId ? $scope.getBookingSystemId(id) : null;
          var bookingSystemId = $scope.bookingData.bookingSystemId;
          var externalId = $scope.bookingData.externalId;
          var action = currentBookingSystemId ? Api.updateHabitapFacilityMapping(id, externalId) : Api.createHabitapFacilityMapping(id, externalId);
          caller.call(action).then(function(res){
            if (currentBookingSystemId)
              $scope.bookingSystems[currentBookingSystemId][id] = null;
            processHabitapFacilityMapping(res);
            $scope.bookingSystems[bookingSystemId][id] = res;
            $scope.bookingPopup.close();
          }, function(err){
            if (err === KEY.ignore)
              return;
            $rootScope.generalErrorHandler();
          });
        }

        //marker popup to show all parent node
        $scope.deviceParentsPopupOpt = {
            origin: "top right",
            position: "top left"
        }
        $scope.sensorData = {};
        var sensorCaller = Api.createApiCaller();
        $scope.showDeviceParentsPopup = function($i){
            if ($scope.deviceParentsPopup.visible()){
                $scope.deviceParentsPopup.close();
                setTimeout(function(){    //wait after popup closed
                    $scope.deviceParentsPopup.open();
                }, 200);
            } else {
				        $scope.deviceParentsPopup.setOptions({anchor: $("#location .tooltipAnchor div")});
                $scope.deviceParentsPopup.open();
            }
            
            $scope.hoveredMarker = $scope.editMapModel.markers[$i];
            if ($rootScope.haveReadingType($scope.hoveredMarker.applicationType)){
                sensorCaller.cancel();
                sensorCaller = Api.createApiCaller();
                $scope.sensorData = { loading: true };
                sensorCaller.call(Api.getSensorData($scope.hoveredMarker.id)).then(function(res){
                    $scope.sensorData = {};
                    if (res.iaqReading){
                        res.iaqReading.forEach(function(data){
                            if (data.measurement === 'humidity'){
                                $scope.sensorData[data.measurement] = Service.numFmt(data.avg*100);
                            } else if (data.measurement === 'temperature'){
                                $scope.sensorData[data.measurement] = Service.numFmt(data.avg, 1);
                            } else {
                                $scope.sensorData[data.measurement] = Service.numFmt(data.avg);
                            }
                        });
                    }
                    $scope.sensorData.lux = res.lux;
                }, function(err){
                    if (err === KEY.ignore)
                        return;
                    $scope.sensorData.loading = false;
                    $rootScope.generalErrorHandler(true);
                });
            } else if ($scope.hoveredMarker.applicationType === APIKEY.applicationType.energyMeter) {
                sensorCaller.cancel();
                sensorCaller = Api.createApiCaller();
                $scope.sensorData = { loading: true };
                var end = new Date();
                var start = new Date(end);
                start.setHours(0,0,0,0);
                start = Service.getUnixTimestamp(start);
                end = Service.getUnixTimestamp(end);
                sensorCaller.call(Api.getEnergyMeterLog($scope.hoveredMarker.serialId, start, end)).then(function(res){
                    $scope.sensorData = {
                      kwh: res[0].kwh
                    }
                }, function(err){
                    if (err === KEY.ignore)
                        return;
                    $scope.sensorData.loading = false;
                    $rootScope.generalErrorHandler(true);
                });
            }
            $scope.deviceParentNodes = [];
            $scope.floor.nodes.forEach(function(n){
                if (n.deviceIds.indexOf($scope.hoveredMarker.id) != -1)
                    $scope.deviceParentNodes.push(n);
            });
        }

        $scope.logicDropdownOpt = {
            autoWidth: true,
            dataSource: [{
                text: Service.translate("location.logicNA"),
                value: APIKEY.logic.noLogic,
            }, {
                text: Service.translate("location.logicGTE"),
                value: APIKEY.logic.greater,
            }, {
                text: Service.translate("location.logicLTE"),
                value: APIKEY.logic.lower,
            }, {
                text: Service.translate("location.logicEQ"),
                value: APIKEY.logic.equal,
            }],
            dataTextField: "text",
            dataValueField: "value"
        }
        $scope.indexDropdownOpt = {
            autoWidth: true,
            dataSource: [0,1,2,3,4,5]
        }

        $scope.editDevicePropertyData = {};
        $scope.editDevicePropertyWinOpt = {
            title: Service.translate("location.deviceProperty"),
            width: "800px",
            modal: true,
            draggable: false,
            visible: false,
            resizable: false,
            actions: ["Close"]
        }
        $scope.fanSpeedDropdownOpt = {
            dataSource: [APIKEY.fanSpeed.auto, APIKEY.fanSpeed.high, APIKEY.fanSpeed.medium, APIKEY.fanSpeed.low].map(function(v){
                return { text: Service.translate("label." +　v), value: v };
            }),
            dataTextField: "text",
            dataValueField: "value"
        }

        $scope.showEditDevicePropertyWin = function(device){
			$scope.loading=true
			caller.call(Api.getLocationDeviceDefaultInfoPage($stateParams.floorId)).then(function(res){
				$scope.loading=false
				const {devices} = res
				let list = devices.filter(item=>{
					return device.id == item.id
				})
				if(list.length)
					device ={...list[0],...device}
					
				$scope.editDevicePropertyData.saving = false;
				$scope.editDevicePropertyData.id = device.id;
				$scope.editDevicePropertyData.canDim = device.dimmable && device.applicationType === APIKEY.applicationType.light;
				$scope.editDevicePropertyData.defaultDim = device.defaultValue.defaultDimming ? parseInt(device.defaultValue.defaultDimming) : 0;
				$scope.editDevicePropertyData.defaultTemp = Math.floor(device.defaultValue.defaultTemperature/0.5) * 0.5;
				if ($scope.editDevicePropertyData.defaultTemp > $scope.maxDefaultTemp){
				    $scope.editDevicePropertyData.defaultTemp = $scope.maxDefaultTemp;
				} else if ($scope.editDevicePropertyData.defaultTemp < $scope.minDefaultTemp){
				    $scope.editDevicePropertyData.defaultTemp = $scope.minDefaultTemp;
				}
				$scope.editDevicePropertyData.heatIndexInSch = device.thermoProfile.heatIndexInSchedule || 0;
				$scope.editDevicePropertyData.heatIndexOutSch = device.thermoProfile.heatIndexOutSchedule || 0;
				$scope.editDevicePropertyData.logicInSch = device.thermoProfile.logicInSchedule || APIKEY.logic.noLogic;
				$scope.editDevicePropertyData.logicOutSch = device.thermoProfile.logicOutSchedule || APIKEY.logic.noLogic;
				$scope.editDevicePropertyData.isAircon = device.applicationType === APIKEY.applicationType.aircon;
				$scope.editDevicePropertyData.logicCo2 = device.co2Profile.logic || APIKEY.logic.noLogic;
				$scope.editDevicePropertyData.co2 = device.co2Profile.threshold || 0;
				$scope.editDevicePropertyData.defaultFanSpeed = device.defaultFanSpeed;
				let time = setTimeout(function(){
				    $scope.editDevicePropertyWin.center().open();
					clearTimeout(time)
				});
			}, function(err){
			    if (err === KEY.ignore)
			        return;
			    $rootScope.generalErrorHandler();
			});

           
        }
        $scope.saveDeviceProperty = function(){
            $scope.editDevicePropertyData.saving = true;
            $rootScope.loadingPage++;

            //updateDevice: if dont give zoneId, location will be null
            var id = $scope.editDevicePropertyData.id;
			// get gSerial before update  
			const gSerial = JSON.parse(JSON.stringify($scope.deviceMap))[id].gSerial
            caller.call(Api.updateDeviceInfo(id, $scope.editDevicePropertyData.defaultTemp, $scope.editDevicePropertyData.defaultDim, $scope.editDevicePropertyData.defaultFanSpeed, $scope.editDevicePropertyData.logicInSch, $scope.editDevicePropertyData.logicOutSch,
                $scope.editDevicePropertyData.heatIndexInSch, $scope.editDevicePropertyData.heatIndexOutSch, $scope.editDevicePropertyData.logicCo2, $scope.editDevicePropertyData.co2, $stateParams.floorId)).then(function(res){
                // save gSerial 
				res['gSerial'] = gSerial
				$scope.deviceMap[id] = res;
                Service.replaceArrItem($scope.floor.devices, res);
                $scope.editDevicePropertyData.saving = false;
                $rootScope.loadingPage--;
                $scope.editDevicePropertyWin.close();
            }, function(err){
                if (err === KEY.ignore)
                    return;
                $scope.editDevicePropertyData.saving = false;
                $rootScope.generalErrorHandler();
            });
        }
    /* edit floor child view */

        $scope.$on('$destroy', function() {
            console.log("locationController destroy");
            caller.cancel();
        });
    }
})();